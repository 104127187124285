import { onLoad } from "./utils";

onLoad(() => {
  const params = new URLSearchParams(window.location.search);
  if (document.querySelector(".message-box") && params.has("msg")) {
    params.delete("msg");
    params.delete("msgTitle");
    params.delete("msgType");
    params.delete("msgLink");
    params.delete("msgLinkText");
    params.delete("msgIsDismissable");

    const paramsStr = params.size ? "?" + params.toString() : "";

    window.history.replaceState(window.history.state, "", `${location.pathname}${paramsStr}${location.hash}`);
  }
});