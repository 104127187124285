import $ from "jquery";

import { isTouchDevice, onLoad } from "../common/ts/utils";

const closeMenu = (elem: JQuery) => {
    elem.removeClass("open");
    // close all open children once parent has been closed
    setTimeout(() => elem.find("ul.open").removeClass("open"), 250);
}

const isLinkToCurrentPage = (href: string, removeQueryString: boolean) => {
    let normalisedHref = href;
    if (removeQueryString && normalisedHref.includes("?")) {
        normalisedHref = normalisedHref.split("?")[0];
    }

    let currentPath = location.pathname;
    if (!removeQueryString) {
        currentPath += location.search;
    }

    return currentPath == href
        || currentPath == normalisedHref;
}

const onItemClick = (e: JQuery.ClickEvent, forceClickToOpen: boolean) => {
    if (isTouchDevice() || forceClickToOpen) {
        const elem = $(e.target);
        const children = elem.next("ul")
        if (!children.hasClass("open")) {
            e.preventDefault();

            if (isTouchDevice()) {
                // close other open menu items on large touch screen devices using the desktop style (e.g. tablets)
                if (window.matchMedia("screen and (min-width : 768px) and (min-height: 500px)").matches) {
                    const currentRoot = elem.closest("nav > ul > li");

                    // find all open root elements except the root of the current element
                    currentRoot.parent().children("li.children-open").not(currentRoot).each((_, e) => {
                        const openRoot = $(e);

                        // close all sub items of each root
                        openRoot.find(".open").removeClass("open");
                        openRoot.find(".children-open").add(openRoot).removeClass("children-open");
                    });
                }
            }

            children.addClass("open");
            elem.parent().addClass("children-open");
        }
    }
}

onLoad(() => {
    $("nav ul:not(.pagination) li a").each((_, e) => {
        const elem = $(e);
        const href = elem.attr("href");
        if (href && isLinkToCurrentPage(href, href.includes("?"))) {
            elem.parent().addClass("active").parentsUntil("nav", "li").addClass("child-active");
        }
    });

    // mobile menu opening and closing
    $(".menu-trigger").on("click", (e) => {
        const menu = $(e.currentTarget.dataset.targetMenu!);
        if (!menu.hasClass("open")) {
            menu.addClass("open");
        } else {
            closeMenu(menu);
        }
    });

    $(".menu-backdrop").on("click", (e) => {
        closeMenu($(e.target).prev("nav"));
    });


    // open/close sub items
    $("nav li.has-children > a").on("click", (e) => onItemClick(e, false));

    // always use click to open instead of hover on image menu children
    $("nav ul.image li.has-children > a").off("click").on("click", (e) => onItemClick(e, true));

    // display menu on left instead of right if it will go off screen
    $(".menu-container nav > ul > li > ul").each((_i1, e1) => {
        $(e1).find("ul").each((_i2, e2) => {
            const bounds = e2.getBoundingClientRect();
            if (bounds.right > window.innerWidth) {
                e1.classList.add("left-menu");
                return false;
            }
        });
    });
});