import $ from "jquery";
import "jquery-validation";
import "jquery-validation-unobtrusive";
import { onLoad } from "./utils";

const validatorSettings: JQueryValidation.ValidationOptions = {
    validClass: 'is-valid',
    errorClass: 'is-invalid',
    ignore: '.ignore-validation'
};

declare global {
    interface Window {
        __RECAPTCHA_LOAD__: () => void
    }
}

onLoad(() => {
    $.validator.setDefaults(validatorSettings);

    //#region Checkbox validation
    $.validator.addMethod("cbrequired", (_v, e, _p) => {
        const input = e as HTMLInputElement;
        if (input.type === "checkbox") {
            return input.checked;
        }

        return true;
    });
    $.validator.unobtrusive.adapters.addBool("cbrequired");
    //#endregion

    //#region Checkbox list/radio button validation
    $.validator.addMethod("listrequired", (_v, e, _p) => {
        const selected = e.parentElement!.querySelector("input[type=checkbox]:checked,input[type=radio]:checked");
        return !!selected;
    });
    $.validator.unobtrusive.adapters.addBool("listrequired");
    //#endregion

    //#region reCAPTCHA
    $.validator.addMethod("recaptcha", (v, _e, _p) => v === "true");
    $.validator.unobtrusive.adapters.addBool("recaptcha");
    window.__RECAPTCHA_LOAD__ = () => {
        for (const elem of document.querySelectorAll<HTMLElement>(".g-recaptcha")) {
            const callback = (success: boolean) => {
                const input = elem.parentElement!.querySelector<HTMLInputElement>("input[type=hidden]");
                input!.value = success.toString();
            }

            grecaptcha.render(elem, {
                "callback": () => callback(true),
                "expired-callback": () => callback(false)
            });
        }
    };

    //#endregion

    //#region File Validation
    $.validator.addMethod('filemaxsize', function (_value, element, params) {
        const uploadField: HTMLInputElement = (element as HTMLInputElement);
        const maxSizeBytes: number = parseInt(params as string);
        let isValid = true;

        [...uploadField.files!].forEach((f) => {
            if (f.size > maxSizeBytes) {
                isValid = false;
            }
        });

        return isValid;
    });
    $.validator.unobtrusive.adapters.addSingleVal('filemaxsize', 'size');

    $.validator.addMethod('fileallowedextensions', function (_value, element, params) {
        const uploadField: HTMLInputElement = (element as HTMLInputElement);
        const allowedExtensions = (params as string).split(',');
        let isValid = true;

        [...uploadField.files!].forEach((f) => {
            const fileEx = `.${f.name.split('.')[1]}`;

            if (!allowedExtensions.find(e => e === fileEx)) {
                isValid = false;
            }
        });

        return isValid;
    });
    $.validator.unobtrusive.adapters.addSingleVal('fileallowedextensions', 'allowed');
    //#endregion

    // @ts-ignore: the options property is missing from the typings for some reason
    $.validator.unobtrusive.options = validatorSettings;
});