export type Response<T> = {
    ok: boolean,
    status: number,
    data?: T
}

export default class HttpClient {
    static getUrlWithQuery = (url: string, params: Record<string, string | undefined>) => {
        let result: URLSearchParams
        const index = url.indexOf("?");
        if (index > -1) {
            result = new URLSearchParams(url.substring(index));
        } else {
            result = new URLSearchParams();
        }

        for (const key in params) {
            if (params[key] !== undefined) {
                result.set(key, params[key]!);
            }
        }

        return `${url}?${result.toString()}`;
    }

    static get = async <T,>(url: string, options: RequestInit = {}): Promise<Response<T>> => {
        const response = await fetch(url, options);
        if (response.ok) {
            return {
                ok: true,
                status: response.status,
                data: await response.json() as T
            };
        } else {
            return {
                ok: false,
                status: response.status
            };
        }
    }

    static post = async<T>(url: string, body: unknown = undefined, options: RequestInit = {}): Promise<Response<T>> => this.sendWithBody<T>(url, body, options, "POST");

    static patch = async<T>(url: string, body: unknown = undefined, options: RequestInit = {}): Promise<Response<T>> => this.sendWithBody<T>(url, body, options, "PATCH");

    static delete = async<T>(url: string, body: unknown = undefined, options: RequestInit = {}): Promise<Response<T>> => this.sendWithBody<T>(url, body, options, "DELETE");

    private static sendWithBody = async <T>(url: string, body: unknown, options: RequestInit = {}, method: string): Promise<Response<T>> => {
        const requestOptions = {
            method: method,
            ...options,
        };

        if (body !== undefined) {
            if (body instanceof FormData) {
                requestOptions.body = body;
            } else {
                requestOptions.body = JSON.stringify(body);
                requestOptions.headers = { "Content-Type": "application/json", ...requestOptions.headers };
            }
        }

        const response = await fetch(url, requestOptions);

        if (response.ok) {
            const result: Response<T> = { ok: true, status: response.status };
            try {
                result.data = await response.json() as T
            } catch {
                result.data = undefined;
            }

            return result;
        } else {
            return {
                ok: false,
                status: response.status
            };
        }
    }
}