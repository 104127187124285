export * from "./data-structures";
export * from "./HttpClient";
export * from "./path";
export * from "./routing";

/**
 * @description Run a function once the page has loaded (DOMContentLoaded has fired). Runs straight away if document
 * has already loaded.
 * @param {func} function to run
 */
export const onLoad = (func: () => unknown) => {
  if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", func);
  } else {
      func();
  }
};

export const isTouchDevice = () => window.matchMedia("(hover: none)").matches;

export const combineCssClasses = (classes: (string | null)[]) => classes.filter(c => c?.length).join(" ");

export function clValid(elt: Element, contains = ""): boolean {
    let result = false;

    if (elt != null) {
        if (contains && contains !== "") {
            result = elt.classList.length > 0 && elt.classList.contains(contains) ? true : false;
        }
        else {
            result = elt.classList.length > 0 ? true : false;
        }
    }

    return result;
}

export function classList(elt: Element) {
    const list = elt.classList;

    return {
        toggle: function (c: string) { list.toggle(c); return this; },
        add: function (c: string) { list.add(c); return this; },
        remove: function (c: string) { list.remove(c); return this; }
    };

}

export function removeClass(group: NodeListOf<HTMLElement>, classString: string, exclusion: Element | null = null): void {
    group.forEach((item) => {
        exclusion != null ?
            item != exclusion && classList(item).remove(classString) :
            classList(item).remove(classString);
    })
}


export const localStorageAvailable = () => {
  try {
    const x = "__storage_test__";
    window.localStorage.setItem(x, x);
    window.localStorage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      window.localStorage &&
      window.localStorage.length !== 0
    );
  }
}

export const tryGetLocalStorageItem = (key: string) => localStorageAvailable()
  ? window.localStorage.getItem(key)
  : null;

export const testFunction = (x: number) => x;