import { createApp } from "vue";
import { onLoad } from "@common/ts/utils";

import Marquee from "./Marquee.vue";

// https://coreui.io/vue/docs/components/popover.html

export const initMarquee = () => {
    document.querySelectorAll(".marquee-component").forEach(e => {

        const carouselItems = e.querySelectorAll<HTMLElement>("li");

        // defaults
        const options = {
            items: carouselItems
        }

        const app = createApp(Marquee, options);
        app.mount(e);
    });
}

onLoad(() => {
    if (window.URLSearchParams) {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get("edit") != 'true') {
            initMarquee();
        }
    }
});